<template>
	<div>
		<h5 class="info-text">Tell us about your most recent studies</h5>
		<div class="md-layout">
			<notifications></notifications>
			<div class="md-layout" v-if="qualifications">
				<md-table v-model="qualification" md-card>
					<md-table-toolbar>
						<h1 class="md-title">Education</h1>
					</md-table-toolbar>

					<md-table-row v-for="qualification in qualifications" :key="qualification.qualificationNum">
						<md-table-cell md-label="Institution" md-sort-by="institution">{{ qualification.institution }}</md-table-cell>
						<md-table-cell md-label="Degree" md-sort-by="email">{{ qualification.degree }}</md-table-cell>
						<md-table-cell md-label="Status" md-sort-by="status" v-if="qualification.studying === 'Yes'">Currently Studying</md-table-cell>
						<md-table-cell md-label="Status" md-sort-by="status" v-else>Completed</md-table-cell>
						<md-table-cell>
							<md-button class="md-icon-button" @click="viewStudies(qualification.qualificationNum)">
								<md-icon>visibility</md-icon>
								<md-tooltip md-direction="bottom">View</md-tooltip>
							</md-button>
						</md-table-cell>
						<md-table-cell>
							<md-button class="md-icon-button" @click="deleteStudies(qualification)">
								<md-icon>delete</md-icon>
								<md-tooltip md-direction="bottom">Delete</md-tooltip>
							</md-button>
						</md-table-cell>
					</md-table-row>
				</md-table>
			</div>
			<div v-else>
				<h6 class="info-text">You currently have no studies added</h6>
			</div>
		</div>
		<md-button class="md-plain jb-standard md-fab-bottom-right" @click="addStudies"> <md-icon>add</md-icon>Add Studies </md-button>
		<education-modal :student="student" :selectedQualification="selectedQualification" :status="status" />
	</div>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions';
import EducationModal from '@/components/Modals/EducationModal';
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { mapActions } from 'vuex';
import { watchEffect } from 'vue';

export default {
	components: {
		EducationModal,
	},
	data() {
		return {
			user: null,
			student: null,
			qualifications: [],
			qualification: null,
			selectedQualification: null,
			status: null,
		};
	},
	methods: {
		validate() {
			return this.$validator.validateAll().then(res => {
				this.$emit('on-validated', res);
				return res;
			});
		},
		async deleteStudies(selected) {
			await this.student
				.collection('qualifications')
				.doc('qualification' + selected.qualificationNum)
				.delete();

			this.$notify({
				message: 'Your selected studies have been deleted!',
				icon: 'add_alert',
				horizontalAlign: 'center',
				verticalAlign: 'top',
				type: 'success',
			});

			const qualifcationIndex = this.qualifications.findIndex(x => x.qualificationNum === selected.qualificationNum);
			this.qualifications.splice(qualifcationIndex, 1);
			this.fetchData();
		},
		viewStudies(selected) {
			this.status = 'update';
			this.selectedQualification = selected;
			this.modalShow();
		},
		addStudies() {
			this.status = 'add';
			this.selectedQualification = null;
			this.modalShow();
		},
		fetchData() {
			this.user = firebase.auth().currentUser;
			let ref = db.collection('users');
			const unsub = ref
				.where('userId', '==', this.user.uid)
				.get()
				.then(snapshot => {
					snapshot.forEach(doc => {
						this.student = db.collection('students').doc(doc.id);
						this.student.collection('qualifications').onSnapshot(snapshot => {
							const changes = snapshot.docChanges();

							changes.forEach(change => {
								if (change.type === 'added') {
									this.qualifications.push({
										...change.doc.data(),
									});

									this.incrementQualificationNum();

									let qualificationsValid = this.qualifications.length > 0;
									this.$emit('qualificationsValid', qualificationsValid);
								}
								if (change.type === 'modified') {
									let pos = this.qualifications
										.map(function (e) {
											return e.qualificationNum;
										})
										.indexOf(this.selectedQualification);
									this.qualifications = this.qualifications.map((qualificationEl, index) => {
										if (index === pos) {
											return change.doc.data();
										} else {
											return qualificationEl;
										}
									});
								}
								if (change.type === 'removed') {
									let pos = this.qualifications
										.map(function (e) {
											return e.qualificationNum;
										})
										.indexOf(this.selectedQualification);
									this.qualifications.splice(pos, 1);

									let qualificationsValid = this.qualifications.length > 0;
									this.$emit('qualificationsValid', qualificationsValid);
								}
							});
							localStorage.setItem('educationMixpanelList', JSON.stringify(this.qualifications));
						});
					});
				});
		},
		...mapActions(['modalShow', 'incrementQualificationNum']),
	},
	created() {
		this.fetchData();
	},
};
</script>
<style scoped>
@media only screen and (max-width: 768px) {
	.md-field label {
		font-size: 11px;
	}
}
@media only screen and (max-width: 768px) {
	.md-card {
		overflow: scroll;
	}
}
</style>
