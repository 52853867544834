<template>
	<div class="application-container">
		<div v-if="loading" class="background"></div>
		<div v-if="loading" class="text-center lds-circle">
			<div><img src="@/assets/img/logo.png" /></div>
		</div>
		<md-card class="padding">
			<md-button
				style="position: absolute; top: 3px; left: 10px; font-size: 30px !important"
				class="md-simple md-just-icon md-round modal-default-button"
				@click="openJobsHelp"
			>
				<md-icon>help</md-icon>
			</md-button>
			<h1 class="info-text centre">{{ job.name }}</h1>
			<div class="md-layout">
				<div class="applicationButton">
					<md-button
						v-if="
							(application && application.applicationStatus && application.applicationStatus === ApplicationStatus.WITHDRAWN) ||
							!application ||
							(application && application.applicationStatus && application.applicationStatus === ApplicationStatus.IN_PROGRESS)
						"
						class="jb-standard"
						@click="apply"
						>{{ auth ? 'Apply for job' : 'Login to Apply' }}</md-button
					>
					<md-button
						v-if="application && application.applicationStatus && application.applicationStatus === ApplicationStatus.APPLIED"
						class="md-danger"
						@click="withdrawModal = true"
					>
						Withdraw Application
					</md-button>
					<md-button
						v-if="auth && job && job.additionalSubmissions && job.additionalSubmissions.writtenSubmission === 'Yes' && isVetted"
						style="background-color: #5c99ee !important; margin: 0 2rem !important"
						@click="openWrittenSubmissionsModal"
					>
						Upload Written Submission
					</md-button>
					<md-button
						v-if="auth && job && job.additionalSubmissions && job.additionalSubmissions.videoSubmission === 'Yes' && isVetted"
						style="background-color: #4e77ed !important"
						@click="openVideoSubmissionsModal"
					>
						Upload Video Submission
					</md-button>

					&nbsp;
					<div v-if="this.previouslyApplied">
						<p class="error">You previously applied for this job and withdrew your application</p>
					</div>
					<div v-if="application && application.applicationStatus && application.applicationStatus === ApplicationStatus.DISQUALIFIED">
						<p class="error" style="font-size: 20px">You are do not qualify to apply for this job</p>
					</div>
				</div>

				<p style="color: red">{{ feedback }}</p>
				<md-card>
					<md-card-content>
						<collapse :collapse="['Job Description', 'Job Requirements', 'Job Information', 'Salary']" icon="keyboard_arrow_down" color-collapse="success">
							<template slot="md-collapse-pane-1">
								<md-card class="bg-success">
									<md-card-content>
										<h3 class="card-category card-category-social" style="text-align: center"><i class="fas fa-list-ul" /> Job Description</h3>
										<h4 class="card-title">Job Name</h4>
										<p class="card-description">{{ job.name }}</p>

										<h4 class="card-title">Company Name</h4>
										<h4 style="margin-top: -1px; font-size: 15px">
											<a v-if="client.website" :href="client.website.startsWith('http') ? client.website : `//${client.website}`" target="_blank">{{
												job.companyName
											}}</a>
											<router-link v-else-if="auth" class="card-title" :to="{ name: 'view-client-profile', params: { id: job.clientAlias } }"
												><a>{{ job.companyName }}</a></router-link
											>
											<p v-else class="card-description">{{ job.companyName }}</p>
										</h4>

										<h4 class="card-title">Description</h4>
										<p class="card-description" v-html="job.description"></p>

										<h4 class="card-title">Job Category</h4>
										<p class="card-description">{{ jobSkills.category || 'Other' }}</p>

										<h4 class="card-title">Skills Required</h4>
										<ul>
											<li v-for="(skill, index) in jobSkills.skills" :key="index" class="card-description">{{ skill.skillName }}</li>
										</ul>
									</md-card-content>
								</md-card>
							</template>
							<template slot="md-collapse-pane-2">
								<md-card class="bg-success">
									<md-card-content>
										<h3 class="card-category card-category-social" style="text-align: center"><i class="fas fa-dungeon"></i> Job Requirements</h3>
										<h4 class="card-title">Minimum Level of Education</h4>
										<p class="card-description">{{ job.education }}</p>

										<h4 class="card-title">Minimum Work Experience</h4>
										<p class="card-description">{{ job.experience }}</p>

										<div v-if="job && job.citizenship">
											<h4 class="card-title">Required Citizenship</h4>
											<p class="card-description" v-for="(citizenship, index) in job.citizenship" :key="index">{{ citizenship }}</p>
										</div>
									</md-card-content>
								</md-card>
							</template>
							<template slot="md-collapse-pane-3">
								<md-card class="bg-success">
									<md-card-content>
										<h3 class="card-category card-category-social centre"><i class="fas fa-clipboard-list"></i> Job Information</h3>
										<h4 class="card-title">Location</h4>
										<p class="card-description">{{ job.location }}</p>

										<h4 class="card-title">Application Deadline</h4>
										<p class="card-description">{{ job.date }}</p>

										<h4 v-if="job.type === 'Once-off Project/Task'" class="card-title">Estimated Duration</h4>
										<h4 v-else class="card-title">Working Days</h4>

										<p v-if="job.type === 'Once-off Project/Task'" class="card-description">{{ job.duration }}</p>
										<ul v-else>
											<li v-for="days in job.daysOfTheWeek" :key="days" class="card-description">{{ days }}</li>
										</ul>

										<h4 v-if="job.type === 'Internship' || job.type === 'Part-time'" class="card-title">Working Hours Per Week</h4>
										<p v-if="job.type === 'Internship' || job.type === 'Part-time'" class="card-description">{{ job.workingHours }}</p>
									</md-card-content>
								</md-card>
							</template>
							<template slot="md-collapse-pane-4">
								<md-card class="bg-success">
									<md-card-content>
										<h3 v-if="job.type === 'Once-off Project/Task'" class="card-category card-category-social centre"><i class="fas fa-wallet"></i> Budget</h3>
										<h3 v-else class="card-category card-category-social centre"><i class="fas fa-wallet"></i> Salary</h3>
										<h4 v-if="job.type === 'Once-off Project/Task'" class="card-title">Budget</h4>
										<h4 v-else class="card-title">Salary</h4>
										<p class="card-description">{{ formatBudget(job) }}</p>
									</md-card-content>
								</md-card>
							</template>
						</collapse>
					</md-card-content>
				</md-card>
				<md-button class="jb-standard" v-show="auth" @click="back"> Back </md-button>
			</div>
		</md-card>
		<!-- Modal: Error handling -->
		<modal v-if="!isVetted && modal" @close="modalHide">
			<template #header>
				<h4 class="modal-title black">{{ header }}</h4>
				<md-button
					style="position: absolute; top: 3px; left: 10px; font-size: 30px !important"
					class="md-simple md-just-icon md-round modal-default-button"
					@click="openVettingHelp"
				>
					<md-icon>help</md-icon>
				</md-button>
			</template>

			<template #body>
				<div style="text-align: left">
					<p>You need to be assessed for the following skills:</p>
					<ul>
						<li v-for="(skill, index) in skillsToTest" :key="index">{{ skill.skillName || skill }}</li>
					</ul>
					<p>Would you like to take the required tests to apply for this job?</p>
				</div>
			</template>

			<template #footer>
				<div class="centre">
					<!-- Sent Test -->
					<router-link :to="{ name: 'apply' }">
						<md-button v-if="sentTest" class="md-button md-success">Got it</md-button>
					</router-link>
					<!-- Send Test -->
					<md-button v-if="!sentTest" class="md-button md-danger" @click="modalHide">No</md-button>
					&nbsp;&nbsp;
					<md-button v-if="!sentTest" class="md-button md-success" @click="test">Yes</md-button>
				</div>
			</template>
		</modal>

		<modal v-if="withdrawModal" @close="withdrawModal = false">
			<template slot="body">
				<p class="black">Are you sure you want to withdraw your application?</p>
			</template>

			<template slot="footer">
				<div class="centre">
					<md-button class="md-button md-danger" @click="withdrawModal = false">No</md-button>
					&nbsp;&nbsp;
					<md-button class="md-button md-success" @click="withdrawApplication(application)">Yes</md-button>
				</div>
			</template>
		</modal>
		<modal v-if="successModal" @close="successModal = false">
			<template slot="header">
				<h4 class="modal-title black">Congratulations!</h4>
			</template>

			<template slot="body">
				<p class="black">Your application has been sent. We will let you know the outcome of your application.</p>
			</template>

			<template slot="footer">
				<div class="centre">
					<md-button v-if="!sentTest" class="md-button md-success" @click="closeModal">Got it</md-button>
				</div>
			</template>
		</modal>

		<modal v-if="writtenSubmissionModal">
			<template slot="header">
				<div>
					<md-button
						style="position: absolute; top: 3px; left: 10px; font-size: 30px !important"
						class="md-simple md-just-icon md-round modal-default-button"
						@click="openWrittenSubmissionHelp"
					>
						<md-icon>help</md-icon>
					</md-button>
					<div>
						<md-button
							style="position: absolute; top: 3px; right: 10px; font-size: 30px !important"
							class="md-simple md-just-icon md-round modal-default-button"
							@click="closeWrittenSubmissionsModal"
						>
							<md-icon>clear</md-icon>
						</md-button>
					</div>
				</div>
			</template>
			<template slot="body">
				<div class="submission-modal">
					<h4 class="modal-title black" style="font-weight: bold">Written Submission</h4>
					<br />
					<p>
						Please submit the written piece <span style="font-weight: bold">as a PDF</span>
						<span v-if="job.additionalSubmissions.submissionLink !== ''"> about the following:</span>
					</p>
					<p class="submission-text">
						<a :href="job.additionalSubmissions.submissionLink" target="_blank">{{ job.additionalSubmissions.submissionLink }}</a>
					</p>
				</div>
				<md-field>
					<md-file @change="previewPdf" v-model="writtenSubmission" accept="application/pdf" placeholder="Upload PDF" class="custom-placeholder" />
				</md-field>
				<p v-if="feedback" style="color: green !important">{{ feedback }}</p>
				<p v-if="message" style="color: red !important">{{ message }}</p>

				<div style="width: 100%; display: flex; align-items: center; justify-content: center">
					<md-button v-if="feedback" class="md-button md-success" @click="confirmFeedbackOnWrittenSubmissionModal">Submit</md-button>
					&nbsp;&nbsp;
				</div>
			</template>
		</modal>

		<modal v-if="videoSubmissionModal">
			<template slot="header">
				<md-button
					style="position: absolute; top: 3px; left: 10px; font-size: 30px !important"
					class="md-simple md-just-icon md-round modal-default-button"
					@click="openVideoSubmissionHelp"
				>
					<md-icon>help</md-icon>
				</md-button>
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="cancelVideoOptionSubmission">
					<md-icon>clear</md-icon>
				</md-button>
			</template>
			<template slot="body">
				<div class="submission-modal">
					<h4 class="modal-title black" style="font-weight: bold">Video Submission</h4>
					<br />
					<p>Please submit a video <span v-if="job.additionalSubmissions.submissionLink !== ''"> on the following:</span></p>
					<p class="submission-text">
						<a :href="job.additionalSubmissions.submissionLink" target="_blank">{{ job.additionalSubmissions.submissionLink }}</a>
					</p>
				</div>

				<div class="submission-modal">
					<template>
						<div class="submission-btn">
							<button ref="myButton" class="centered-button">Record Submission</button>
							<md-button class="md-button md-success" @click="submitVideoSubmissionModal"> Upload Submission Link</md-button>
						</div>
					</template>
				</div>
			</template>
		</modal>

		<modal v-if="submitVideoModal">
			<template slot="header">
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="cancelVideoSubmission">
					<md-icon>clear</md-icon>
				</md-button>
			</template>
			<template slot="body">
				<div class="submission-modal">
					<h4 class="modal-title black" style="font-weight: bold">Video Submission</h4>
					<br />
					<p>
						Please submit the video as a <span style="font-weight: bold"> Google Drive link or a Loom link</span>
						<span v-if="job.additionalSubmissions.submissionLink !== ''"> about the following:</span>
					</p>
					<p class="submission-text">
						<a :href="job.additionalSubmissions.submissionLink" target="_blank">{{ job.additionalSubmissions.submissionLink }}</a>
					</p>
				</div>
				<md-field>
					<md-input v-model="videoSubmission" placeholder="Upload Submission Link" style="text-align: center"></md-input>
				</md-field>
				<p v-if="feedback" style="color: green !important">{{ feedback }}</p>
				<p v-if="videoSubmissionMessage" style="color: red !important">{{ videoSubmissionMessage }}</p>

				<div style="width: 100%; display: flex; align-items: center; justify-content: space-between">
					<md-button v-if="feedback" class="md-button md-success" @click="closeWrittenSubmissionsModal">Okay</md-button>
					&nbsp;&nbsp;
				</div>
			</template>
			<template slot="footer">
				<div style="width: 100%; display: flex; align-items: center; justify-content: center">
					<md-button class="md-success" @click="submitVideoSubmissionUrl">Submit</md-button>
				</div>
			</template>
		</modal>

		<modal v-if="confirmVideoSubmission">
			<template slot="body">
				<p>Video has been Successfully Submitted</p>
			</template>
			<template slot="footer">
				<md-button class="md-success" @click="closeVideoConfirmationModal"> Ok </md-button>
			</template>
		</modal>

		<modal v-if="applyAfterSubmission">
			<template slot="body"> <h3>Please Submit Your Application!</h3> </template>
			<template slot="footer">
				<md-button class="md-success" @click="apply"> Submit Application </md-button>
			</template>
		</modal>

		<modal v-if="loomBrowserSupportError">
			<template slot="body"> <h4>Oops! Your browser isn't supported, please try a different web browser and contact Jobox for support</h4> </template>
			<template slot="footer">
				<md-button
					class="md-success"
					@click="
						() => {
							this.loomBrowserSupportError = false;
						}
					"
				>
					OK
				</md-button>
			</template>
		</modal>

		<modal v-if="loomTokenError">
			<template slot="body"> <h4>Oops! Something went wrong with video recording, please contact Jobox for support</h4> </template>
			<template slot="footer">
				<md-button
					class="md-success"
					@click="
						() => {
							this.loomTokenError = false;
						}
					"
				>
					OK
				</md-button>
			</template>
		</modal>

		<modal v-if="noStudyInformation">
			<template #header> <h3 style="color: black">Whoa there! ⛔️</h3></template>
			<template #body>
				<p>Please complete your profile by adding your qualification details before proceeding with vetting.</p>
			</template>
			<template #footer>
				<md-button class="md-success" @click="redirectToStudentProfile"> Ok </md-button>
			</template>
		</modal>
	</div>
</template>
<script>
import { isSupported, setup } from '@loomhq/record-sdk';
import firebase from 'firebase/compat/app';
import db from '@/firebase/init';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import { Modal, Collapse } from '@/components';
import moment from 'moment';
import slugify from 'slugify';
import Mixpanel from '@/mixpanel/mixpanel';
import { logSystemError } from '../../../../../../helpers/error.helper.js';
import { helperArticles } from '@/constants/helper-articles.const.js';
import { mapGetters, mapActions } from 'vuex';
import { htmlDecode, htmlEncode } from '../../../../../../helpers/encoder.helper';
import { ApplicationStatus } from '../../../../../../constants/application-status';
export default {
	components: {
		Modal,
		Collapse,
	},
	data() {
		return {
			ApplicationStatus,
			loomTokenError: false,
			loomBrowserSupportError: false,
			submitVideoModal: false,
			isVetted: false,
			applyAfterSubmission: false,
			confirmVideoSubmission: false,
			videoSubmission: null,
			videoSubmissionMessage: null,
			message: null,
			writtenSubmissionUrl: null,
			writtenSubmission: null,
			writtenSubmissionModal: false,
			videoSubmissionModal: false,
			withdrawModal: false,
			successModal: false,
			client: {},
			url: {},
			job: {},
			jobSkills: {},
			student: {},
			vetted: {},
			sentTest: false,
			feedback: null,
			header: null,
			user: null,
			auth: null,
			slug: null,
			modal: false,
			loading: true,
			qualifications: [],
			application: null,
			previouslyApplied: false,
			vettingUrl: 'https://joinjobox.com/vetting-tests/',
			currentApplicationStatus: '',
			jobId: this.$route.params.jobId,
			studentAcquiredSkills: new Map(),
			skillsToTest: [],
			passedTestsCount: 0,
			writtenSkillTests: [],
			acquiredSkills: [],
			acquiredSkillsCount: 0,
			loomToken: '',
			noStudyInformation: false,
		};
	},
	methods: {
		openVettingHelp() {
			window.open(helperArticles.VETTING_HELP, '_blank');
		},
		openJobsHelp() {
			window.open(helperArticles.OPEN_JOBS_HELP, '_blank');
		},
		openVideoSubmissionHelp() {
			window.open(helperArticles.VIDEO_SUBMISSION_HELP, '_blank');
		},
		openWrittenSubmissionHelp() {
			window.open(helperArticles.WRITTEN_SUBMISSION_HELP, '_blank');
		},
		async getLoomToken() {
			try {
				const generateToken = firebase.functions().httpsCallable('getLoomToken');
				const result = await generateToken();
				return result.data;
			} catch (error) {
				this.loading = false;
				this.loomTokenError = true;
				logSystemError(this.user.alias, this.auth.email, this.auth.uid, 'Application.vue', error);
			}
		},
		async initialiseLoom() {
			try {
				const { supported, error } = await isSupported();

				if (!supported) {
					this.loomBrowserSupportError = true;
					this.loading = false;
					logSystemError(this.user.alias, this.auth.email, this.auth.uid, 'Application.vue', error);
					return;
				}

				const loomToken = await this.getLoomToken();

				if (!loomToken) {
					this.loomTokenError = true;
					this.loading = false;
					return;
				}

				const button = this.$refs.myButton;

				const { configureButton } = await setup({
					publicAppId: loomToken.publicId,
					jws: loomToken.token,
				});

				const sdkButton = configureButton({ element: button });

				this.loading = false;

				sdkButton.on('insert-click', async video => {
					this.addVideoLink(video.embedUrl);
				});
			} catch (error) {
				this.loomTokenError = true;
				this.loading = false;
				logSystemError(this.user.alias, this.auth.email, this.auth.uid, 'Application.vue', error);
			}
		},
		addVideoLink(videoLink) {
			if (videoLink) {
				this.videoSubmission = videoLink;
				db.collection('applications').doc(this.slug).update({
					videoSubmissionUrl: videoLink,
				});
				this.videoSubmissionModal = false;
				this.confirmVideoSubmission = true;
			}
		},
		submitVideoSubmissionModal() {
			this.submitVideoModal = true;
		},
		closeVideoConfirmationModal() {
			this.confirmVideoSubmission = false;
			this.submitVideoModal = false;

			if (this.currentApplicationStatus != 'Applied') {
				this.submitApplication();
			}
		},
		cancelVideoOptionSubmission() {
			this.videoSubmissionModal = false;
			this.videoSubmissionMessage = '';
			this.videoSubmission = null;
		},
		cancelVideoSubmission() {
			this.submitVideoModal = false;
			this.videoSubmissionMessage = '';
			this.videoSubmission = null;
		},
		submitApplication() {
			if (this.job.additionalSubmissions.videoSubmission === 'Yes' && this.job.additionalSubmissions.writtenSubmission === 'Yes') {
				if (this.checkVideoSubmission() && this.checkWrittenSubmision()) {
					this.applyAfterSubmission = true;
				}
			} else {
				if (this.checkVideoSubmission() || this.checkWrittenSubmision()) {
					this.applyAfterSubmission = true;
				}
			}
		},
		checkVideoSubmission() {
			if (this.job.additionalSubmissions.videoSubmission === 'Yes' && this.videoSubmission) {
				return true;
			}
		},
		checkWrittenSubmision() {
			if (this.job.additionalSubmissions.writtenSubmission === 'Yes' && this.writtenSubmission) {
				return true;
			}
		},
		submitVideoSubmissionUrl() {
			const isValidLink = this.checkIsLink(this.videoSubmission);
			if (this.videoSubmission && isValidLink) {
				this.videoSubmissionModal = false;
				db.collection('applications').doc(this.slug).update({
					videoSubmissionUrl: this.videoSubmission,
				});
				this.confirmVideoSubmission = true;
				this.videoSubmissionMessage = '';
			} else {
				this.videoSubmissionMessage = 'Please enter a valid video submission link';
			}
		},
		checkIsLink(data) {
			const urlPattern = /^(http:\/\/|https:\/\/)/;
			return urlPattern.test(data);
		},
		openWrittenSubmissionsModal() {
			this.feedback = null;
			this.writtenSubmissionModal = true;
		},
		openVideoSubmissionsModal() {
			this.loading = true;
			this.feedback = null;
			this.initialiseLoom();
			this.videoSubmissionModal = true;
		},
		previewPdf(event) {
			this.message = null;
			this.feedback = null;
			const file = event.target.files[0];
			const validationError = this.validateWrittenSubmission(file);
			if (validationError) {
				return;
			}
			this.fileUpload(file, 'written-submission');
		},
		validateWrittenSubmission(writtenSubmission) {
			this.feedback = null;
			if (!writtenSubmission) {
				return (this.message = 'Please upload a file.');
			}
			if (writtenSubmission.type !== 'application/pdf') {
				return (this.message = 'Please upload a PDF file.');
			}
			if (writtenSubmission.size > 2 * 1024 * 1024) {
				return (this.message = 'The file size exceeds the maximum limit of 2MB.');
			}

			return null; // Validation passed
		},
		async fileUpload(data, location) {
			this.loading = true;
			this.feedback = null;
			try {
				const storageRef = firebase
					.storage()
					.ref()
					.child('users/students/' + this.user.alias + '/' + location + '/' + data.name);

				const snapshot = await storageRef.put(data);
				this.writtenSubmissionUrl = await snapshot.ref.getDownloadURL();
			} catch (e) {
				logSystemError(this.user.alias, this.auth.email, this.auth.uid, 'Application.vue', e);
				console.error('Error uploading file or updating URL.');
			} finally {
				this.loading = false;
				this.feedback = 'Written Submission Has Been Successfully Uploaded';
			}
		},
		closeWrittenSubmissionsModal() {
			this.writtenSubmissionModal = false;
		},
		confirmFeedbackOnWrittenSubmissionModal() {
			this.writtenSubmissionModal = false;
			if (this.currentApplicationStatus != 'Applied') {
				this.submitApplication();
			}
		},
		confirmFeedbackOnVideoSubmissionModal() {
			this.videoSubmissionModal = false;
		},
		closeModal() {
			this.successModal = false;
			window.open('https://docs.google.com/forms/d/e/1FAIpQLSeH5xssMPVGVhPyX4GkzKc1pz-MTjbTKq__bP6DA7IDrKkclQ/viewform');
		},
		checkIsVetted() {
			const currentJobId = this.$route.params.id;
			this.acquiredSkillsCount = 0;
			this.skillsToTest = [];
			this.jobSkills.skills.forEach(skill => {
				const studentSkill = this.studentAcquiredSkills.get(skill.skillName.trim().toLowerCase());
				if (studentSkill) {
					const acquiredJobSkills = studentSkill.testResults.some(testResult => testResult?.pass);
					if (acquiredJobSkills) {
						this.acquiredSkillsCount++;
					}

					const completedOrPassedTest = studentSkill.testResults.some(
						testResult => testResult.jobId === currentJobId || (testResult.jobId !== currentJobId && testResult.pass)
					);
					if (!completedOrPassedTest) {
						this.skillsToTest.push(skill);
					}
				} else {
					this.skillsToTest.push(skill);
				}
			});

			this.isVetted = this.acquiredSkillsCount >= 3;
		},
		...mapActions(['studentCancelSelect']),
		withdrawApplication(application) {
			this.currentApplicationStatus = 'Withdrawn';
			this.studentCancelSelect(application);
		},
		isApplicantNull() {
			return this.application != null ? true : false;
		},
		modalHide() {
			this.modal = false;
			this.isVetted = false;

			Mixpanel.notProceedingWithVetting({
				university: this.qualifications[0].institution,
				faculty: this.qualifications[0].faculty,
				yearOfStudy: this.qualifications[0].year,
				gender: this.student.gender,
				ethnicity: this.student.race,
				emailAddress: this.user.email,
				citizen: this.student.citizenship,
				jobId: this.job.jobId,
				jobName: this.job.name,
				companyIndustry: this.client.industry,
				companyName: this.job.companyName,
				companySize: this.client.companySize,
			});
		},
		formatBudget(job) {
			let budget = '';
			if (job?.budget?.min || job?.budget?.max) {
				let { min, max } = job.budget;
				budget = 'R' + min + ' ' + '-' + ' ' + 'R' + max;
				return budget;
			} else {
				budget = 'R' + job.budget;
				return budget;
			}
		},
		async apply() {
			this.applyAfterSubmission = false;
			if (!this.auth) {
				this.$router.push({ name: 'Login' });
				return;
			}

			if (this.job) {
				this.noStudyInformation = this.qualifications?.length === 0;
				if (this.noStudyInformation) return;
				this.loading = true;
				const application = db.collection('applications').doc(this.slug);
				application.get().then(async doc => {
					if (doc.exists && doc.data().jobId == htmlEncode(this.$route.params.id) && doc.data()?.applicationStatus !== 'Withdrawn') {
						const error = db.collection('errors');
						error.add({
							jobId: this.job.id,
							userId: this.auth.uid,
							errorDate: moment(Date.now()).format('LLL'),
							message: 'A duplicate application was proccessed by the same user.',
						});
					}
					this.checkIsVetted();
					if (this.isVetted) {
						this.application = (await application.get()).data();

						const { additionalSubmissions } = this.job;
						if (additionalSubmissions) {
							if (additionalSubmissions.writtenSubmission === 'Yes' && !this.writtenSubmission) {
								this.feedback = 'Please upload a written submission';
								this.loading = false;
								return;
							}
							if (additionalSubmissions.videoSubmission === 'Yes' && !this.videoSubmission) {
								this.feedback = 'Please upload the link to your video submisison';
								this.loading = false;
								return;
							}
						}

						await application.set({
							jobId: this.job.id,
							jobType: 'micro',
							jobName: this.job.name,
							studentId: this.auth.uid,
							created: moment(Date.now()).format('L'),
							lastModified: moment(Date.now()).format('L'),
							applicant: this.auth.displayName,
							clientName: this.job.clientName,
							degree: this.qualifications,
							bio: this.student.bio,
							alias: this.user.alias,
							applicantEmail: this.user.email,
							clientEmail: this.job.clientEmail,
							approved: false,
							status: ApplicationStatus.APPLIED,
							applicationStatus: ApplicationStatus.APPLIED,
							interviewRequestSent: false,
							favourite: false,
							writtenSubmissionUrl: this.writtenSubmissionUrl,
							videoSubmissionUrl: this.videoSubmission,
						});
						this.loading = false;
						this.successModal = true;
						this.currentApplicationStatus = ApplicationStatus.APPLIED;
					} else {
						this.successModal = false;
						this.loading = false;
						this.modal = true;
						this.header = 'Whoa there! ⛔️';
					}
				});
			} else {
				this.modal = true;
				this.feedback = 'This job is currently unavailable';
				this.header = 'Oops!';
				let error = db.collection('errors');
				error.add({
					jobId: this.job.id,
					userId: this.auth.uid,
					errorDate: moment(Date.now()).format('LLL'),
					message: 'Unable to apply due to job data not loading.',
				});
				this.loading = false;
			}
		},
		back() {
			this.$router.go(-1);
		},
		redirectToStudentProfile() {
			this.$router.push({ name: 'edit-student-profile', params: { id: this.auth.uid } });
		},
		async test() {
			this.modal = false;
			const application = db.collection('applications').doc(this.slug);
			await application.set({
				jobId: this.job.id,
				jobType: 'micro',
				jobName: this.job.name,
				studentId: this.auth.uid,
				created: moment(Date.now()).format('L'),
				lastModified: moment(Date.now()).format('L'),
				applicant: this.auth.displayName,
				clientName: this.job.clientName,
				degree: this.qualifications,
				bio: this.student.bio,
				alias: this.user.alias,
				applicantEmail: this.user.email,
				clientEmail: this.job.clientEmail,
				approved: false,
				status: ApplicationStatus.IN_PROGRESS,
				applicationStatus: ApplicationStatus.IN_PROGRESS,
				interviewRequestSent: false,
				favourite: false,
				writtenSubmissionUrl: this.writtenSubmissionUrl,
				videoSubmissionUrl: this.videoSubmission,
			});
			this.$router.push({ name: 'vetting-tests', params: { jobId: this.job.jobId } });
		},
		getDocumentId() {
			const isAuthenticated = firebase.auth().currentUser;
			const isEncoded = this.$route.params.id !== decodeURIComponent(this.$route.params.id);
			if (isAuthenticated || isEncoded) {
				return this.$route.params.id;
			} else {
				return encodeURIComponent(this.$route.params.id);
			}
		},
	},
	async mounted() {
		const meta = document.createElement('meta');
		meta.setAttribute('name', 'referrer');
		meta.setAttribute('content', 'origin');
		document.head.appendChild(meta);
	},
	async created() {
		const id = this.getDocumentId();
		const job = db.collection('jobs').doc(id);

		const project = db.collection('micros').doc(id);
		const skills = db.collection('skills').doc(id);
		project.get().then(async doc => {
			this.job = doc.data();

			this.job.id = doc.id;
			job.get().then(doc => {
				const applicationDeadline = doc.data().applicationDeadline;
				if (applicationDeadline) {
					this.job.date = moment(applicationDeadline).format('LL');
				} else {
					this.job.date = 'No deadline specified';
				}
				this.job.type = doc.data().jobType;
				this.job.experience = doc.data().experience;
				this.job.education = doc.data().education;
			});

			skills.get().then(doc => {
				this.jobSkills = doc.data();
				this.jobSkills.id = doc.id;
			});

			this.client = (await db.collection('clients').doc(this.job.clientAlias).get()).data();
			this.auth = firebase.auth().currentUser || null;

			if (!this.auth) {
				this.loading = false;
				return;
			}
			db.collection('users')
				.where('userId', '==', this.auth.uid)
				.get()
				.then(async snapshot => {
					snapshot.forEach(async doc => {
						this.user = doc.data();
						this.slug = slugify(`${this.user.alias} ${htmlEncode(this.$route.params.id)}`, {
							replacement: '-',
							remove: /[$*_+~.()'"!\-:@]/g,
							lower: true,
						});
						const application = db.collection('applications').doc(this.slug);
						application.get().then(doc => {
							this.currentApplicationStatus = doc.data()?.applicationStatus;
							this.previouslyApplied = doc.exists ? doc.data().applicationStatus === ApplicationStatus.WITHDRAWN : false;
							if (doc.exists && doc.data().jobId == htmlEncode(this.$route.params.id) && !this.previouslyApplied) {
								this.application = doc.data();
								this.application.id = this.slug;
							} else {
								this.application = doc.data();
							}
							this.loading = false;
						});
						const studentRef = db.collection('students').doc(this.user.alias);
						this.student = (await studentRef.get()).data();
						const studentSkills = await studentRef.collection('skills').get();
						studentSkills.docs.map(doc => this.studentAcquiredSkills.set(doc.id.trim().toLowerCase(), doc.data()));

						const qualificationsRef = await studentRef.collection('qualifications').get();
						qualificationsRef.forEach(doc => {
							this.qualifications.push(doc.data());
						});
					});
				});
		});
	},
};
</script>
<style scoped>
.submission-modal {
	display: flex;
	flex-direction: column;
	text-align: center;
	margin: 0px 30px;
}
.submission-btn {
	display: flex;
	flex-direction: column;
	text-align: center;
	margin: 0px 30px;
	justify-content: center;
	align-items: center;
}

.centered-button {
	width: 205px;
	background: linear-gradient(90deg, hsla(21, 100%, 56%, 1) 0%, hsla(14, 100%, 57%, 1) 100%) !important;
	border: none;
	color: white;
	padding: 15px 10px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 12px;
	margin: 4px 2px;
	cursor: pointer;
	border-radius: 4px;
	text-transform: uppercase;
}
.btn-controls {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 200px;
}
.padding {
	padding: 20px;
}
.centre {
	text-align: center;
}
.applicationButton {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: row;
}
.error {
	color: red;
}

.application-container {
	padding: 40px;
	padding-top: 0px;
}

.card-description {
	color: #353535 !important;
}
.card-title {
	font-weight: 500 !important;
	margin-bottom: 0px !important;
}

.modal-header .md-button .md-button-content i {
	font-size: 30px !important;
}

.md-button .md-button-content i {
	font-size: 30px !important;
}
@media (max-width: 530px) {
	.application-container {
		padding: 0;
	}
	.padding[data-v-46e15736] {
		padding: 0;
	}

	.info-text.centre {
		font-size: 20px;
		font-weight: bold;
	}
	.applicationButton[data-v-46e15736] {
		align-items: center;
		justify-content: center;
	}
}
</style>
