import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// namespaced compatibility
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
	apiKey: 'AIzaSyBZWTf4IMJvaxI-9ZUjv8JTjjr4jUmqm9s',
	authDomain: 'jobox-live-821b5.firebaseapp.com',
	projectId: 'jobox-live-821b5',
	storageBucket: 'jobox-live-821b5.appspot.com',
	messagingSenderId: '415380809893',
	appId: '1:415380809893:web:f8f69fadc18a7956d7454c',
	measurementId: 'G-J1N40D6P9K',
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig); //namespaced compatibility
const firebaseApp = initializeApp(firebaseConfig);

export default app.firestore();
export const db = getFirestore(firebaseApp);
