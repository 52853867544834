<template>
	<questions-taker-vue />
</template>

<script>
import QuestionsTakerVue from '@/components/QuestionsTaker.vue';
export default {
	components: { QuestionsTakerVue },
	data() {
		return {
			skills: null,
		};
	},
};
</script>

<style scoped>
.container {
	width: 100%;
	height: 100vh;
	background-color: white;
	overflow: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.container > h1 {
	text-align: center;
}
</style>
