// Vue
import Vue from 'vue';
import Router from 'vue-router';
// Firebase
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
// Routes
import Auth from './auth/auth';
import StudentDashboard from './student/student';
import ClientDashboard from './client/client';
import ClientAccount from './client/account/account';
import StudentAccount from './student/account/account';
import InstitutionDashboard from './institution/institution';
import StudentJobs from './student/jobs';
import InterviewAuthFailure from '../views/dashboard/client/jobs/status/micro/flow/interview-status/InterviewAuthFailure.vue';
import InterviewAuthSuccess from '../views/dashboard/client/jobs/status/micro/flow/interview-status/InterviewAuthSuccess.vue';

Vue.use(Router);
// window.Intercom('boot', {
// 	api_base: 'https://api-iam.intercom.io',
// 	app_id: 'bm4lz6d8',
// });
const router = new Router({
	mode: 'history',
	routes: [
		{ path: '/', redirect: 'register/:refCode?', name: 'Register' },
		{
			path: '/interview/failure',
			name: 'InterviewAuthFailure',
			component: InterviewAuthFailure,
		},
		{
			path: '/interview/success',
			name: 'InterviewAuthSuccess',
			component: InterviewAuthSuccess,
		},
		Auth,
		ClientAccount,
		StudentAccount,
		ClientDashboard,
		StudentDashboard,
		InstitutionDashboard,
		StudentJobs,
	],
	scrollBehavior(to, from, savedPosition) {
		const container = document.getElementsByClassName('main-panel');
		if (container.length > 0) {
			return (container[0].scrollTop = 0);
		}
		if (to.hash) {
			return {
				selector: to.hash,
			};
		}
		if (savedPosition) {
			return savedPosition;
		}
		return { x: 0, y: 0 };
	},
});

function previous(back) {
	router.go(back);
}

router.beforeEach(async (to, from, next) => {
	// checking to see if route requires authentication
	let requiresAuth = to.matched.some(rec => rec.meta.requiresAuth);
	// show apply for job
	let applyForJob = to.matched.some(rec => rec.meta.applyForJob);
	// check authentication state of user
	let user = firebase.auth().currentUser;
	// if the user has authentication information
	if (applyForJob) {
		next();
		return;
	}
	if (requiresAuth) {
		// if the user is signed in
		if (user) {
			const ref = await db.collection('users').where('userId', '==', user.uid).get();
			ref.docs.forEach(doc => {
				let userPermission = doc.data().user;

				// checking to see if the user role matches
				let userRoleExist = to.matched.some(rec => rec.meta.userRole == userPermission);

				// if user role matches
				if (userRoleExist) {
					// checking to see if user's email has been verified
					let emailVerified = to.matched.some(rec => rec.meta.emailVerified == user.emailVerified);
					let verifyClientUser = to.matched.some(rec => rec.name == 'verify-client-users');
					let verifyStudentUser = to.matched.some(rec => rec.name == 'verify-student-users');
					// if the email is verified
					if (emailVerified) {
						if (router.history._startLocation.startsWith('/jobs/micro')) {
							const routeId = router.history._startLocation.split('/');
							const decodedSegment = decodeURIComponent(routeId[routeId.length - 1]);
							const properlyEncodedSegment = encodeURIComponent(decodedSegment);
							const desiredPath = `/student/jobs/micro/application/${properlyEncodedSegment}`;
							const currentPath = router.currentRoute?.path;
							const doubleEncodedPath = `/student/jobs/micro/application/${encodeURIComponent(properlyEncodedSegment)}`;
							if (currentPath !== desiredPath && currentPath !== doubleEncodedPath && !currentPath.includes('/student/jobs/vetting/tests')) {
								router.push({ name: 'micro-application', params: { id: properlyEncodedSegment } });
								return;
							}
							next();
						} else {
							next();
						}
					} // else if the email account is not verified but directing to student or client account page
					else if (!emailVerified && (verifyClientUser || verifyStudentUser)) {
						next();
					} // otherwise the email account has not been verified and not directing to student or client account page
					else {
						previous(-1);
					}
				} // otherwise the user role does not match
				else {
					previous(-1);
				}
			});
		} // otherwise no user has signed in
		else {
			previous(-1);
		}
	} // logout if the user does not require auth but user is authenticated
	else if (!requiresAuth && user) {
		firebase
			.auth()
			.signOut()
			.then(() => {
				next();
			});
	}
	// otherwise the user does not require authentication
	else {
		next();
	}
});

export default router;
