<template>
	<div>
		<div>
			<form @submit.prevent="confirmChoices">
				<h1 class="md-headline" style="font-size: 14px; text-align: center">Select the three companies you would love to work for in the future</h1>

				<div class="company-input">
					<md-field style="margin-right: 30px">
						<label>What is your first choice company?</label>
						<md-input type="search" v-model="firstChoice" @click="openFirstCompanySelection"></md-input>
					</md-field>
					<md-button @click="openFirstCompanySelection" class="md-success"> {{ this.firstChoice != null ? 'Edit' : 'Add' }}</md-button>
				</div>

				<div class="company-input">
					<md-field style="margin-right: 30px">
						<label>What is your second choice company?</label>
						<md-input type="search" v-model="secondChoice" @click="openSecondCompanySelection"></md-input>
					</md-field>
					<md-button @click="openSecondCompanySelection" class="md-success"> {{ this.secondChoice != null ? 'Edit' : 'Add' }}</md-button>
				</div>

				<div class="company-input">
					<md-field style="margin-right: 30px">
						<label>What is your third choice company?</label>
						<md-input type="search" v-model="thirdChoice" @click="openThirdCompanySelection"></md-input>
					</md-field>
					<md-button @click="openThirdCompanySelection" class="md-success"> {{ this.thirdChoice != null ? 'Edit' : 'Add' }}</md-button>
				</div>

				<div class="department-input">
					<md-field style="margin-right: 30px">
						<label>
							Lastly, what departments do you want to work in? <br />
							Select a minimum of 1 department and a maximum of 3.
						</label>
						<div v-if="v$.department.$error" class="error-message" style="margin-top: 60px; width: 70%">Select your preferred departments</div>
						<md-table v-model="department" md-card class="department-tble">
							<md-table-row slot="md-table-row" slot-scope="{ item }">
								<md-table-cell>{{ item.department }}</md-table-cell>
							</md-table-row>
						</md-table>
					</md-field>
					<div class="department-input-btn">
						<md-button @click="openDepartmentSelection" class="md-success"> {{ this.department.length > 0 ? 'Edit' : 'Add' }} </md-button>
					</div>
				</div>
			</form>
		</div>

		<preffered-companies-seletion
			v-if="openFirstCompanyModal"
			:modalHeading="'Select your first company'"
			:openCompanyModal="openFirstCompanyModal"
			@cancelSelection="cancelFirstSelection"
			@companySelection="firstCompanySelection"
		></preffered-companies-seletion>

		<preffered-companies-seletion
			v-if="openSecondCompanyModal"
			:modalHeading="'Select your second company'"
			:openCompanyModal="openSecondCompanyModal"
			@cancelSelection="cancelSecondSelection"
			@companySelection="secondCompanySelection"
		></preffered-companies-seletion>

		<preffered-companies-seletion
			v-if="openThirdCompanyModal"
			:modalHeading="'Select your third company'"
			:openCompanyModal="openThirdCompanyModal"
			@cancelSelection="cancelThirdSelection"
			@companySelection="thirdCompanySelection"
		></preffered-companies-seletion>

		<department-selection
			v-if="openDepartmentModal"
			:companyDepartments="companyDepartments"
			:openDepartmentModal="openDepartmentModal"
			@cancelDepartmentSelection="cancelDepartmentSelection"
			@departmentSelection="departmentSelection"
		>
		</department-selection>
	</div>
</template>

<script>
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import { useVuelidate } from '@vuelidate/core';
import { helpers, minLength } from '@vuelidate/validators';
import { requiredValidator } from '@/helpers/validators.helper';

export default {
	data() {
		return {
			openSecondCompanyModal: false,
			openFirstCompanyModal: false,
			openThirdCompanyModal: false,
			openCompanyModal: false,
			firstChoice: null,
			secondChoice: null,
			thirdChoice: null,
			department: [],
			companies: [],
			companyDepartments: [],
			choices: [],
			openDepartmentModal: false,
		};
	},
	components: {
		PrefferedCompaniesSeletion: () => import('@/views/dashboard/student/preferred-companies/preffered-companies-seletion.vue'),
		DepartmentSelection: () => import('@/views/dashboard/student/department/department-selection.vue'),
	},
	setup: () => ({
		v$: useVuelidate({
			$lazy: true,
			$autoDirty: false,
		}),
	}),
	validations: {
		firstChoice: { required: requiredValidator },
		secondChoice: { required: requiredValidator },
		thirdChoice: { required: requiredValidator },
		department: {
			required: requiredValidator,
			minLength: minLength(3),
		},
	},
	methods: {
		cancelDepartmentSelection() {
			this.openDepartmentModal = false;
		},
		cancelSelection(value) {
			this.openCompanyModal = false;
		},
		cancelFirstSelection(value) {
			this.openFirstCompanyModal = value;
		},
		cancelSecondSelection(value) {
			this.openSecondCompanyModal = value;
		},
		cancelThirdSelection(value) {
			this.openThirdCompanyModal = value;
		},
		firstCompanySelection(item) {
			this.firstChoice = item.companyName;
			this.openFirstCompanyModal = false;
		},
		async secondCompanySelection(item) {
			this.secondChoice = item.companyName;
			this.openSecondCompanyModal = false;
		},
		async thirdCompanySelection(item) {
			this.thirdChoice = item.companyName;
			this.openThirdCompanyModal = false;
		},
		async openFirstCompanySelection() {
			this.openFirstCompanyModal = true;
		},
		async openSecondCompanySelection() {
			this.openSecondCompanyModal = true;
		},
		async openThirdCompanySelection() {
			this.openThirdCompanyModal = true;
		},
		async openDepartmentSelection() {
			this.openDepartmentModal = true;
		},
		departmentSelection(value) {
			this.department = value;
		},
		async validate() {
			const res = await this.v$.$validate();
			this.$emit('on-validated', res);
			if (res) {
				this.addChoices();
				this.addDepartment();
			}
			return res;
		},
		async confirmChoices() {
			const isFormValid = await this.v$.$validate();
			if (isFormValid) {
				this.addChoices();
				this.addDepartment();
			}
		},
		getErrorMessage(field) {
			const fieldObj = this.v$[field];
			return fieldObj?.$dirty && fieldObj?.$error && fieldObj?.$errors[0]?.$message;
		},
		addChoices() {
			if (this.firstChoice) {
				this.choices.splice(0, 1, this.firstChoice);
			}
			if (this.secondChoice) {
				this.choices.splice(1, 1, this.secondChoice);
			}
			if (this.thirdChoice) {
				this.choices.splice(2, 1, this.thirdChoice);
			}
			this.$emit('choices', this.choices);
		},
		addDepartment() {
			this.$emit('department', this.department);
		},
		async getCompanies() {
			const snapshot = await db.collection('Settings').doc('Preferred Employers').get();
			let temp = snapshot.data().companies;
			for (let i = 0; i < temp.length; i++) {
				this.companies.push(temp[i]);
			}
		},
		async getDepartments() {
			//Departments Drop Down List
			let departmentRef = await db.collection('Settings').doc('Departments').get();
			this.companyDepartments = departmentRef.data().departments;
		},
		async isNullOrUndefinedOrEmpty(obj) {
			return obj === null || obj === undefined || (typeof obj === 'object' && Object.keys(obj).length === 0);
		},
	},
	async created() {
		await this.getCompanies();
		await this.getDepartments();
		// Pulling student saved companies
		this.user = firebase.auth().currentUser;
		let ref = db.collection('users');
		ref.where('userId', '==', this.user.uid)
			.get()
			.then(snapshot => {
				snapshot.forEach(async doc => {
					if (doc.exists) {
						this.alias = doc.id;
						let studentRef = await db.collection('students').doc(doc.id).get();
						this.student = studentRef.data();
						let studentCompanies = this.student?.preferredCompanies || {};
						if (!this.isNullOrUndefinedOrEmpty(studentCompanies)) {
							this.firstChoice = studentCompanies?.choices?.[0] || '';
							this.secondChoice = studentCompanies?.choices?.[1] || '';
							this.thirdChoice = studentCompanies?.choices?.[2] || '';
							this.department = studentCompanies?.department;
						}
					}
				});
			});
	},
};
</script>

<style scoped>
.company-input {
	display: flex;
	flex-direction: row;
	margin: 10px 30px;
}
.department-input {
	display: flex;
	flex-direction: row;
	margin: 10px 30px;
}
.department-input-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 60px;
}
.department-tble {
	margin-top: 60px;
}
.md-table-head-container {
	display: none !important;
}
.error-message {
	color: red;
}
</style>
