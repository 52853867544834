<template>
	<div class="md-layout">
		<div class="md-layout-item md-size-66 md-xsmall-size-80 mx-auto">
			<simple-wizard
				v-bind:image="image"
				v-bind:dob="dob"
				v-bind:gender="gender"
				v-bind:race="race"
				v-bind:location="location"
				v-bind:relocation="relocation"
				v-bind:citizenship="citizenship"
				v-bind:identification="identification"
				v-bind:passport="passport"
				v-bind:disability="disability"
				v-bind:disabilityDescription="disabilityDescription"
				v-bind:license="license"
				v-bind:vehicle="vehicle"
				v-bind:programme="programme"
				v-bind:bio="bio"
				v-bind:studying="studying"
				v-bind:institution="institution"
				v-bind:campus="campus"
				v-bind:studentNo="studentNo"
				v-bind:faculty="faculty"
				v-bind:degree="degree"
				v-bind:major="major"
				v-bind:year="year"
				v-bind:graduateStatus="graduateStatus"
				v-bind:accountName="accountName"
				v-bind:accountNumber="accountNumber"
				v-bind:accountType="accountType"
				v-bind:bankName="bankName"
				v-bind:branchCode="branchCode"
				v-bind:email="email"
				v-bind:linkedIn="linkedIn"
				v-bind:facebook="facebook"
				v-bind:twitter="twitter"
				v-bind:instagram="instagram"
				v-bind:github="github"
				v-bind:certificate1="certificate1"
				v-bind:certificate2="certificate2"
				v-bind:certificate3="certificate3"
				v-bind:portfolio="portfolio"
				v-bind:personalWebsite="personalWebsite"
				v-bind:cv="cv"
				v-bind:industryCategory="industryCategory"
				v-bind:extraCirricularActivities="extraCirricularActivities"
				v-bind:qualificationsValid="qualificationsValid"
				v-bind:choices="choices"
				v-bind:department="department"
				@emailVerified="addEmailVerified"
				@feedback="addFeedback"
			>
				<template slot="header">
					<h3 v-if="emailVerified" class="title" style="color: #000">Build your profile</h3>
					<h5 v-if="emailVerified" class="category">This information will let us know more about you</h5>
					<div v-else>
						<p slot="inputs" style="color: red">
							Please check your inbox to verify that <b>{{ email }}</b> is the email address that you are currently using
						</p>
						<md-button @click="verification" class="jb-standard"> Resend verification </md-button>
						<br /><br />
					</div>
				</template>

				<wizard-tab :before-change="() => validateStep('step1')">
					<template slot="label">
						<div class="pc-view">About</div>
						<div class="mobi-view"><i class="fas fa-user"></i></div>
					</template>
					<first-step
						ref="step1"
						@on-validated="onStepValidated"
						@image="addImage"
						@dob="addDob"
						@gender="addGender"
						@race="addRace"
						@location="addLocation"
						@relocation="addRelocation"
						@citizenship="addCitizenship"
						@identification="addIdentification"
						@passport="addPassport"
						@disability="addDisability"
						@disabilityDescription="addDisabilityDescription"
						@license="addLicense"
						@programme="addProgramme"
						@vehicle="addVehicle"
						@bio="addBio"
					>
					</first-step>
				</wizard-tab>

				<wizard-tab :before-change="() => validateStep('step2')">
					<template slot="label">
						<div class="pc-view">Studies</div>
						<div class="mobi-view"><i class="fas fa-university"></i></div>
					</template>
					<second-step
						ref="step2"
						@on-validated="onStepValidated"
						@institution="addInstitution"
						@studying="addStudying"
						@startDate="addStartDate"
						@endDate="addEndDate"
						@faculty="addFaculty"
						@degree="addDegree"
						@year="addYear"
						@graduateStatus="addGraduateStatus"
						@campus="addCampus"
						@studentNo="addStudentNo"
						@qualificationsValid="addQualificationsValid"
					>
					</second-step>
				</wizard-tab>

				<wizard-tab :before-change="() => validateStep('step3')">
					<template slot="label">
						<div class="pc-view">Experience</div>
						<div class="mobi-view"><i class="fas fa-briefcase"></i></div>
					</template>
					<third-step ref="step3" @on-validated="onStepValidated"> </third-step>
				</wizard-tab>

				<wizard-tab :before-change="() => validateStep('step4')">
					<template slot="label">
						<div class="pc-view">
							<h1 style="font-size: 10px">Preferred Employer</h1>
						</div>
						<div class="mobi-view"><i class="fas fa-briefcase"></i></div>
					</template>
					<fourth-step ref="step4" @on-validated="onStepValidated" @choices="addChoices" @department="addDepartment"> </fourth-step>
				</wizard-tab>

				<wizard-tab :before-change="() => validateStep('step5')">
					<template slot="label">
						<div class="pc-view">Portfolio</div>
						<div class="mobi-view"><i class="fas fa-globe-africa"></i></div>
					</template>
					<fifth-step
						ref="step5"
						@on-validated="onStepValidated"
						@linkedIn="addLinkedIn"
						@facebook="addFacebook"
						@twitter="addTwitter"
						@instagram="addInstagram"
						@github="addGithub"
						@certificate1="addCertificate1"
						@certificate2="addCertificate2"
						@certificate3="addCertificate3"
						@portfolio="addPortfolio"
						@personalWebsite="addPersonalWebsite"
						@id="addId"
						@cv="addCv"
						@industryCategory="addIndustryCategory"
						@extraCirricularActivities="addExtraCirricularActivity"
					>
					</fifth-step>
				</wizard-tab>

				<!-- <wizard-tab :before-change="() => validateStep('step5')">
					<template slot="label">
						<div class="pc-view">Banking</div>
						<div class="mobi-view"><i class="fas fa-piggy-bank"></i></div>
					</template>
					<sixth-step
						ref="step6"
						@on-validated="onStepValidated"
						@accountName="addAccountName"
						@accountNumber="addAccountNumber"
						@accountType="addAccountType"
						@bankName="addBankName"
						@branchCode="addBranchCode"
					>
					</sixth-step>
				</wizard-tab> -->
			</simple-wizard>
		</div>
		<!-- Modal: Error handling -->
		<modal v-if="modal" @close="modalHide">
			<template slot="header">
				<h4 class="modal-title black">{{ feedbackHeader }}</h4>
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="modalHide">
					<md-icon>clear</md-icon>
				</md-button>
			</template>

			<template slot="body">
				<p class="black">{{ feedback }}</p>
			</template>

			<template slot="footer">
				<div style="text-align: center">
					<md-button class="md-button md-success" @click="modalHide">Got it</md-button>
				</div>
			</template>
		</modal>
	</div>
</template>
<script>
import swal from 'sweetalert2';
import { WizardTab, Modal } from '@/components';
import SimpleWizard from './wizard/Wizard.vue';
import FirstStep from './wizard/formSteps/FirstStep.vue';
import SecondStep from './wizard/formSteps/SecondStep.vue';
import ThirdStep from './wizard/formSteps/ThirdStep.vue';
import FourthStep from './wizard/formSteps/FourthStep.vue';
import FifthStep from './wizard/formSteps/FifthStep';
import SixthStep from './wizard/formSteps/SixthStep.vue';
import firebase from 'firebase/compat/app';
import db from '@/firebase/init';
export default {
	data() {
		return {
			qualificationsValid: false,
			wizardModel: {},
			auth: null,
			user: null,
			feedback: null,
			feedbackHeader: null,
			image: null,
			dob: null,
			gender: null,
			race: null,
			location: null,
			relocation: null,
			citizenship: null,
			id: null,
			identification: null,
			passport: null,
			disability: null,
			disabilityDescription: null,
			license: null,
			vehicle: null,
			programme: null,
			bio: null,
			institution: null,
			studying: null,
			startDate: null,
			endDate: null,
			campus: null,
			studentNo: null,
			faculty: null,
			degree: null,
			major: null,
			year: null,
			graduateStatus: null,
			accountName: null,
			accountNumber: null,
			accountType: null,
			bankName: null,
			branchCode: null,
			linkedIn: null,
			facebook: null,
			twitter: null,
			instagram: null,
			github: null,
			portfolio: null,
			personalWebsite: null,
			certificate1: null,
			certificate2: null,
			certificate3: null,
			cv: null,
			industryCategory: null,
			extraCirricularActivities: null,
			email: null,
			emailVerified: null,
			modal: false,
			choices: null,
			department: null,
		};
	},
	components: {
		FirstStep,
		SecondStep,
		ThirdStep,
		FourthStep,
		FifthStep,
		SimpleWizard,
		WizardTab,
		Modal,
	},

	methods: {
		modalHide() {
			this.modal = false;
		},
		validateStep(ref) {
			return this.$refs[ref].validate();
		},
		onStepValidated(validated, model) {
			this.wizardModel = { ...this.wizardModel, ...model };
		},
		verification() {
			this.user
				.sendEmailVerification()
				.then(() => {
					this.modal = true;
					this.feedbackHeader = 'Check your inbox!';
					this.feedback = 'Email Sent. Check your inbox and verify!';
				})
				.catch(err => {
					// An error happened.
					this.modal = true;
					this.feedback = err.message;
				});
		},
		refresh() {
			if (!this.emailVerified) {
				this.user.reload();
				this.emailVerified = this.user.emailVerified;
			}
		},
		addImage: function (image) {
			this.image = image;
			this.refresh();
		},
		addDob: function (dob) {
			this.dob = dob;
			this.refresh();
		},
		addGender: function (gender) {
			this.gender = gender;
			this.refresh();
		},
		addRace: function (race) {
			this.race = race;
			this.refresh();
		},
		addLocation: function (location) {
			this.location = location;
			this.refresh();
		},
		addRelocation: function (relocation) {
			this.relocation = relocation;
			this.refresh();
		},
		addCitizenship: function (citizenship) {
			this.citizenship = citizenship;
			this.refresh();
		},
		addIdentification: function (identification) {
			this.identification = identification;
			this.refresh();
		},
		addPassport: function (passport) {
			this.passport = passport;
			this.refresh();
		},
		addDisability: function (disability) {
			this.disability = disability;
			this.refresh();
		},
		addDisabilityDescription: function (disabilityDescription) {
			this.disabilityDescription = disabilityDescription;
			this.refresh();
		},
		addLicense: function (license) {
			this.license = license;
			this.refresh();
		},
		addVehicle: function (vehicle) {
			this.vehicle = vehicle;
			this.refresh();
		},
		addProgramme: function (programme) {
			this.programme = programme;
			this.refresh();
		},
		addBio: function (bio) {
			this.bio = bio;
			this.refresh();
		},
		addStudying: function (studying) {
			this.studying = studying;
			this.refresh();
		},
		addInstitution: function (institution) {
			this.institution = institution;
			this.refresh();
		},
		addStartDate: function (startDate) {
			this.startDate = startDate;
			this.refresh();
		},
		addEndDate: function (endDate) {
			this.endDate = endDate;
			this.refresh();
		},
		addCampus: function (campus) {
			this.campus = campus;
			this.refresh();
		},
		addStudentNo: function (studentNo) {
			this.studentNo = studentNo;
			this.refresh();
		},
		addQualificationsValid: function (qualificationsValid) {
			this.qualificationsValid = qualificationsValid;
			this.refresh();
		},
		addFaculty: function (faculty) {
			this.faculty = faculty;
			this.refresh();
		},
		addDegree: function (degree) {
			this.degree = degree;
			this.refresh();
		},
		addMajor: function (major) {
			this.major = major;
			this.refresh();
		},
		addYear: function (year) {
			this.year = year;
			this.refresh();
		},
		addGraduateStatus: function (graduateStatus) {
			this.graduateStatus = graduateStatus;
			this.refresh();
		},
		addAccountName: function (accountName) {
			this.accountName = accountName;
			this.refresh();
		},
		addAccountNumber: function (accountNumber) {
			this.accountNumber = accountNumber;
			this.refresh();
		},
		addAccountType: function (accountType) {
			this.accountType = accountType;
			this.refresh();
		},
		addBankName: function (bankName) {
			this.bankName = bankName;
			this.refresh();
		},
		addBranchCode: function (branchCode) {
			this.branchCode = branchCode;
			this.refresh();
		},
		addLinkedIn: function (linkedIn) {
			this.linkedIn = linkedIn;
			this.refresh();
		},
		addFacebook: function (facebook) {
			this.facebook = facebook;
			this.refresh();
		},
		addTwitter: function (twitter) {
			this.twitter = twitter;
			this.refresh();
		},
		addInstagram: function (instagram) {
			this.instagram = instagram;
			this.refresh();
		},
		addGithub: function (github) {
			this.github = github;
			this.refresh();
		},
		addPortfolio: function (portfolio) {
			this.portfolio = portfolio;
			this.refresh();
		},
		addPersonalWebsite: function (personalWebsite) {
			this.personalWebsite = personalWebsite;
			this.refresh();
		},
		addCertificate1: function (certificate1) {
			this.certificate1 = certificate1;
			this.refresh();
		},
		addCertificate2: function (certificate2) {
			this.certificate2 = certificate2;
			this.refresh();
		},
		addCertificate3: function (certificate3) {
			this.certificate3 = certificate3;
			this.refresh();
		},
		addId: function (id) {
			this.id = id;
			this.refresh();
		},
		addCv: function (cv) {
			this.cv = cv;
			this.refresh();
		},
		addFeedback: function (feedback, feedbackHeader) {
			this.modal = true;
			this.feedbackHeader = feedbackHeader;
			this.feedback = feedback;
		},
		addEmailVerified: function (emailVerified) {
			this.emailVerified = emailVerified;
			this.refresh();
		},
		addIndustryCategory: function (industryCategory) {
			this.industryCategory = industryCategory;
		},
		addExtraCirricularActivity: function (extraCirricularActivities) {
			this.extraCirricularActivities = extraCirricularActivities;
		},
		addChoices: function (choices) {
			this.choices = choices;
		},
		addDepartment: function (department) {
			this.department = department;
		},
	},
	created() {
		this.auth = firebase.auth();
		this.user = this.auth.currentUser;
		this.emailVerified = this.user.emailVerified;
		let ref = db.collection('users');
		ref.where('userId', '==', this.user.uid)
			.get()
			.then(snapshot => {
				snapshot.forEach(doc => {
					this.email = doc.data().email;
				});
			});
	},
};
</script>
<style scoped>
@media only screen and (max-width: 768px) {
	.pc-view {
		display: none;
	}
	.md-layout-item.md-xsmall-size-80 {
		min-width: 100%;
		overflow-x: hidden;
	}
	.md-card-wizard .nav-pills > li i {
		font-size: 0px;
	}
}

@media only screen and (min-width: 768px) {
	.mobi-view {
		display: none;
	}
}

@media (max-width: 450px) {
	.md-layout {
		margin-bottom: 6rem;
	}
}
</style>
