<template>
	<modal v-if="this.openModal">
		<template slot="header">
			<div class="modalHeader">
				<h3>{{ heading }}</h3>
			</div>
		</template>

		<template slot="body">
			<div class="form-group form-check" v-for="item in items" v-bind:key="item.id">
				<div class="itemCheckBox">
					<input type="checkbox" v-model="selectedValues" :id="item.id" :value="item" />
				</div>

				<div class="itemValue">
					<label class="form-check-label" :for="item.id">{{ item.value }}</label>
				</div>
			</div>
		</template>

		<template slot="footer">
			<div class="centre" style="text-align: center">
				<md-button class="md-button md-danger" @click="closeModal"> Cancel</md-button>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<md-button class="md-button md-success" @click="confirmSelection"> Confirm</md-button>
			</div>
		</template>
	</modal>
</template>

<script>
import { Modal } from '@/components';
export default {
	data() {
		return {
			selectedValues: [],
		};
	},
	components: {
		Modal,
	},
	props: {
		openModal: { type: Boolean },
		items: { type: Array },
		heading: { type: String },
	},
	methods: {
		closeModal() {
			this.$emit('cancel', false);
		},
		confirmSelection() {
			this.$emit('confirmSelection', this.selectedValues);
		},
	},
};
</script>
<style>
.form-check {
	display: flex;
	flex-direction: row;
	align-items: center;
	border: 0.2px solid rgb(231, 228, 228);
}
.itemCheckBox {
	display: flex;
	align-content: flex-start;
	margin: 5px 15px;
}
.itemCheckBox input {
	width: 25px;
	height: 25px;
}
.itemValue {
	display: flex;
	text-align: left;
	justify-content: flex-start;
	margin: 5px 15px;
	width: 100%;
}
.modalHeader h3 {
	color: grey !important;
	font-family: sans-serif;
}
</style>
