<template>
	<div>
		<div v-if="loading" class="background"></div>
		<div v-if="loading" class="text-center lds-circle">
			<div><img src="@/assets/img/logo.png" /></div>
		</div>
		<div class="md-layout" v-if="appliedJobs">
			<div class="card-layout md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33" v-for="job in jobs" :key="job.id">
				<product-card header-animation="false">
					<img v-if="!job.profilePicture" class="img" slot="imageHeader" :src="product1" />
					<img v-if="job.profilePicture" class="img" slot="imageHeader" :src="job.profilePicture" />
					<md-icon slot="fixed-button">build</md-icon>
					<template slot="first-button">
						<md-icon>art_track</md-icon>
						<md-tooltip md-direction="bottom">View</md-tooltip>
					</template>
					<h4 slot="title" class="title">
						{{ job.name }}
					</h4>
					<div slot="description" class="card-description">
						{{ job.category }}
					</div>
					<template slot="footer">
						<div class="price">
							<i class="fas fa-money-bill-wave"></i> Job Type
							<h4 class="centre">{{ job.type }}</h4>
						</div>
						<div class="price">
							<router-link :to="{ name: 'student-micro-status', params: { id: job.jobId } }">
								<md-button class="jb-standard">View</md-button>
							</router-link>
						</div>
						<div class="stats">
							<div class="price">
								<md-icon>place</md-icon> Location
								<h4 v-if="job.location !== 'Remote'" class="centre">on-site</h4>
								<h4 v-else class="centre">{{ job.location }}</h4>
							</div>
						</div>
					</template>
				</product-card>
			</div>
		</div>
		<div v-else-if="appliedJobs === false">
			<h1 class="black centre">You have no open applications</h1>
		</div>
	</div>
</template>

<script>
import { ProductCard } from '@/components';
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export default {
	components: {
		ProductCard,
	},
	data() {
		return {
			product1: '/img/dashboard/client/card-1.jpg',
			jobs: [],
			appliedJobs: null,
			loading: true,
		};
	},
	async created() {
		let user = firebase.auth().currentUser;
		this.pendingJobs = true;
		let applications = db.collection('applications');
		let applicationList = await applications.where('studentId', '==', user.uid).where('status', '!=', 'cancel').get();
		applicationList.docs.forEach(async doc => {
			this.appliedJobs = true;
			let jobs = await db.collection('jobs').doc(doc.data().jobId).get();
			let job = jobs.data();
			job.type = job.jobType;
			let clients = await db.collection('clients').doc(job.clientAlias).get();
			job.profilePicture = clients.data().profile;
			this.jobs.push(job);
		});
		if (this.appliedJobs === null) this.appliedJobs = false;
		this.loading = false;
	},
};
</script>

<style scoped></style>
