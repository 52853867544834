<template>
	<form @submit.prevent="registerUser" class="md-layout text-center">
		<div v-if="loading" class="background"></div>
		<div v-if="loading" class="lds-circle">
			<div><img src="@/assets/img/logo.png" /></div>
		</div>
		<div class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
			<login-card header-color="green">
				<h3 slot="title" class="title">Register</h3>
				<md-radio v-model="role" slot="inputs" value="student"><md-icon>school</md-icon> Student</md-radio>
				<md-radio v-model="role" slot="inputs" value="client"><md-icon>work</md-icon> Employer</md-radio>

				<text-field slot="inputs" name="firstName" label="First Name" iconName="face" v-model="firstName" :error="getErrorMessage('firstName')"></text-field>
				<text-field slot="inputs" name="lastName" label="Last Name" iconName="person" v-model="lastName" :error="getErrorMessage('lastName')"></text-field>
				<text-field
					slot="inputs"
					name="phoneNumber"
					type="input"
					label="Phone Number"
					iconName="phone"
					v-model="phoneNumber"
					:error="getErrorMessage('phoneNumber')"
				></text-field>
				<text-field
					slot="inputs"
					name="email"
					type="input"
					:label="role === 'student' ? 'Student Email' : 'Email'"
					iconName="email"
					v-model="email"
					:error="getErrorMessage('email')"
				></text-field>
				<text-field
					slot="inputs"
					name="password"
					type="password"
					label="Password"
					iconName="lock_outline"
					v-model="password"
					:error="getErrorMessage('password')"
				></text-field>
				<select-field
					v-if="role"
					slot="inputs"
					label="How did you hear about us?"
					:options="role === 'student' ? studentOptions : employerOptions"
					:iconName="'hearing'"
					:error="getErrorMessage('howDidYouHear')"
					:required="false"
					v-model="howDidYouHear"
					@input="handleHowDidYouHearChange"
				></select-field>

				<text-field
					v-if="role && howDidYouHear === 'Other'"
					slot="inputs"
					name="otherOption"
					label="Other (please specify)"
					iconName="edit"
					v-model="otherOption"
					:error="getErrorMessage('otherOption')"
				></text-field>
				<text-field
					slot="inputs"
					name="referralCode"
					label="Referral Code (optional)"
					iconName="person_add"
					v-model="referralCode"
					:error="getErrorMessage('referralCode')"
				></text-field>

				<md-checkbox v-model="terms" slot="inputs" @change="checkIfLinkOpened" style="font-size: 13px">
					I agree to the <a @click="openTsAndCs" style="font-size: 13px; color: blue">terms and conditions</a>,
					<a @click="openPrivacyPolicy" style="font-size: 13px; color: blue">privacy policy</a>
					and to direct marketing
				</md-checkbox>

				<button class="md-button md-success md-theme-default" slot="footer">
					<div class="md-ripple">
						<div class="md-button-content">Register</div>
					</div>
				</button>
			</login-card>
		</div>

		<modal v-if="modal" @close="modalHide">
			<template slot="header">
				<h4 class="modal-title black">{{ header }}</h4>
				<md-button v-if="error" class="md-simple md-just-icon md-round modal-default-button" @click="modalHide">
					<md-icon>clear</md-icon>
				</md-button>
			</template>
			<template slot="body">
				<p class="black">{{ body }}</p>
			</template>
			<template slot="footer">
				<div class="centre">
					<!-- Modal: Verify Email and continue creating account -->
					<md-button v-if="success" class="md-button md-success" @click="verification">{{ footer }}</md-button>
					<!-- Modal: Error handling -->
					<md-button v-if="error" class="md-button md-success" @click="modalHide">{{ footer }}</md-button>
				</div>
			</template>
		</modal>
	</form>
</template>
<script>
import { LoginCard, Modal, TextField } from '@/components';
import { mapGetters, mapActions } from 'vuex';

import useVuelidate from '@vuelidate/core';
import { required, helpers, numeric, email, maxLength, regex } from '@vuelidate/validators';
import { REG_EXP } from '@/constants/reg-expression.const';
import db from '@/firebase/init';
import SelectField from '@/components/Inputs/SelectField.vue';
const refCodeValidator = async value => {
	if (!value) return true;
	const user = (await db.collection('users').doc(value).get()).data();
	return !!user;
};

const passwordValidator = value => {
	if (!value) return;
	return REG_EXP.PASSWORD.test(value);
};

const specialCharsValidator = value => {
	if (!value) return true;
	return REG_EXP.NO_SPECIAL_CHARS.test(value);
};

const requiredValidator = helpers.withMessage('is required.', required);
const emailValidator = helpers.withMessage('is not a valid email address.', email);
const numericValidator = helpers.withMessage('must be numeric.', numeric);
export default {
	components: {
		LoginCard,
		Modal,
		TextField,
		SelectField,
	},
	data() {
		return {
			firstName: null,
			lastName: null,
			phoneNumber: null,
			email: null,
			password: null,
			terms: false,
			role: null,
			feedback: null,
			referralCode: '',
			openLink: false,
			howDidYouHear: '',
			howDidYouHearOptions: [],
			otherOption: '',
		};
	},
	validations() {
		return {
			firstName: { required: requiredValidator },
			lastName: { required: requiredValidator },
			email: { required: requiredValidator, email: emailValidator },
			phoneNumber: { required: requiredValidator, numeric: numericValidator },
			password: {
				required: requiredValidator,
				passwordValidator: helpers.withMessage('requires a minimum of 8 characters with an uppercase, lowercase, number and special character', passwordValidator),
			},
			terms: { required: requiredValidator },
			referralCode: { refCodeValidator: helpers.withMessage('is not valid.', helpers.withAsync(refCodeValidator)) },
			howDidYouHear: { required: requiredValidator },
			otherOption: {
				maxLength: maxLength(100),
				specialCharsValidator: helpers.withMessage('No special characters are allowed', specialCharsValidator),
				required: this.howDidYouHear === 'Other' ? requiredValidator : false,
			},
		};
	},
	setup: () => ({
		v$: useVuelidate({
			$lazy: true,
			$autoDirty: true,
		}),
	}),
	async created() {
		const { refCode } = this.$route.params;
		this.referralCode = refCode || '';
		const doc = await db.collection('Settings').doc('How Did You Hear About Us').get();
		if (doc.exists) {
			const data = doc.data();
			this.howDidYouHearOptions = { student: data.student, employer: data.employers };
		}
	},
	methods: {
		...mapActions(['register', 'proceed', 'termsAndCondition', 'modalHide', 'verification']),
		async registerUser() {
			const isFormValid = await this.v$.$validate();
			if (isFormValid) {
				await this.register({
					role: this.role,
					firstName: this.firstName,
					lastName: this.lastName,
					phoneNumber: this.phoneNumber,
					email: this.email,
					password: this.password,
					terms: this.terms,
					referralCode: this.referralCode,
					howDidYouHear: this.howDidYouHear,
					otherOption: this.otherOption,
				});
			}
		},
		getErrorMessage(field) {
			const fieldObj = this.v$[field];
			return fieldObj?.$dirty && fieldObj?.$error && fieldObj?.$errors[0]?.$message;
		},
		checkIfLinkOpened(e) {
			if (this.openLink) {
				this.terms = !e;
			}
			this.openLink = false;
		},
		openTsAndCs() {
			window.open('https://joinjobox.com/terms-and-conditions/', '_blank');
			this.openLink = true;
		},
		openPrivacyPolicy() {
			window.open('https://joinjobox.com/privacy-policy/', '_blank');
			this.openLink = true;
		},
		handleHowDidYouHearChange() {
			if (this.howDidYouHear !== 'Other') {
				this.otherOption = '';
			}
		},
	},
	computed: {
		...mapGetters({
			header: 'headerR',
			body: 'bodyR',
			footer: 'footerR',
			modal: 'modalR',
			loading: 'loadingR',
			success: 'successR',
			error: 'errorR',
		}),

		studentOptions() {
			return this.howDidYouHearOptions.student;
		},

		employerOptions() {
			return this.howDidYouHearOptions.employer;
		},
	},
};
</script>

<style scoped>
@media (max-height: 900px) {
	.md-layout {
		margin-top: 3rem;
		margin-bottom: 6rem;
	}
}
</style>
