<template>
	<div>
		<div v-if="loading" class="background"></div>
		<div v-if="loading" class="text-center lds-circle">
			<div>
				<img src="@/assets/img/logo.png" />
				<div class="loading"></div>
			</div>
		</div>

		<div class="md-layout">
			<notifications></notifications>
			<div style="width: 100%; display: flex; align-item: center; justify-content: flex-start; margin-bottom: -7rem">
				<div style="margin-left: 1rem">
					<h4 style="margin-left: 2px">Extracurricular Activities</h4>
					<md-button class="md-plain jb-standard md-fab-bottom-right" @click="addActivity"> <md-icon>add</md-icon>Add Extracurricular Activities</md-button>

					<multi-select
						:openModal="openActivityModal"
						@cancel="closeModal => (openActivityModal = closeModal)"
						:items="activities"
						@confirmSelection="selectedValue => confirmSelection(selectedValue)"
						:heading="this.heading"
					>
					</multi-select>

					<!-- show the selectd items -->
					<div style="margin-bottom: 5rem">
						<md-table v-model="selectedActivities" md-card>
							<md-table-row slot="md-table-row" slot-scope="{ item }">
								<md-table-cell>{{ item.value }}</md-table-cell>
							</md-table-row>
						</md-table>
					</div>
				</div>
			</div>
			<div class="md-layout-item ml-auto mt-4 md-small-size-100" style="margin-top: 2rem">
				<h4>CV/Resume</h4>

				<md-field :class="[{ 'md-valid': !errors.has('cv') && touched.cv }, { 'md-form-group': true }, { 'md-error': cvError }]">
					<label>CV/Resume</label>
					<md-file @change="previewPdf" v-model="cv" data-vv-name="cv" name="CV" v-validate="modelValidations.cv" accept="application/pdf" />
					<slide-y-down-transition>
						<md-icon class="error" v-show="cvError">close</md-icon>
					</slide-y-down-transition>
					<slide-y-down-transition>
						<md-icon class="success" v-show="!cvError && touched.cv">done</md-icon>
					</slide-y-down-transition>
				</md-field>
				<p v-if="!cv" style="color: red; margin-left: 35px; margin-top: -10px; font-size: 10px">Please attach a CV</p>
			</div>

			<br /><br />
		</div>

		<modal v-if="modal" @close="modalHide">
			<template slot="header">
				<h4 class="modal-title black">Whoa there! ✋</h4>
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="modalHide">
					<md-icon>clear</md-icon>
				</md-button>
			</template>
			<template slot="body">
				<p class="black">You cannot exceed the file limit of 2MB</p>
			</template>
			<template slot="footer">
				<div class="centre">
					<!-- Modal: Verify Email and continue creating account -->
					<md-button class="md-button md-success" @click="modalHide">Got it</md-button>
				</div>
			</template>
		</modal>

		<!-- Modal: Alert user to only upload PDF's -->
		<modal v-if="pdfError" @close="PdfModalHide">
			<template slot="header">
				<h4 class="modal-title black">Whoa there! ✋</h4>
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="PdfModalHide">
					<md-icon>clear</md-icon>
				</md-button>
			</template>
			<template slot="body">
				<p class="black">Please add a PDF</p>
			</template>
			<template slot="footer">
				<div class="centre">
					<md-button class="md-button md-success" @click="PdfModalHide">Got it</md-button>
				</div>
			</template>
		</modal>
	</div>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions';
import { Modal } from '@/components';
import db from '@/firebase/init';
import firebase, { storage } from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import moment from 'moment';
import { mapActions } from 'vuex';
import { Activities } from '@/constants/extra-cirricular-activities';
import MultiSelect from '@/components/Inputs/MultiSelect.vue';

export default {
	components: {
		SlideYDownTransition,
		Modal,
		MultiSelect,
	},
	data() {
		return {
			isActivitiesSelected: true,
			idError: null,
			cvError: null,
			portfolioError: null,
			cert1Error: null,
			cert2Error: null,
			cert3Error: null,
			selectedActivities: [],
			openActivityModal: false,
			activities: Activities,
			firebaseUrl: null,
			name: null,
			pdfError: null,
			user: null,
			modal: null,
			alias: null,
			loading: false,
			linkedIn: null,
			facebook: null,
			twitter: null,
			instagram: null,
			github: null,
			portfolio: null,
			certificate1: null,
			certificate2: null,
			certificate3: null,
			personalWebsite: null,
			id: null,
			cv: null,
			uploadCV: 0,
			transcript: null,
			industryCategory: null,
			extraCirricularActivities: null,
			list: [],
			touched: {
				linkedIn: false,
				facebook: false,
				twitter: false,
				instagram: false,
				github: false,
				portfolio: false,
				cv: false,
				certificate1: false,
				certificate2: false,
				certificate3: false,
				personalWebsite: false,
			},
			modelValidations: {
				linkedIn: {
					min: 6,
					url: {
						require_protocol: true,
					},
				},
				facebook: {
					min: 6,
					url: {
						require_protocol: true,
					},
				},
				twitter: {
					min: 6,
					url: {
						require_protocol: true,
					},
				},
				instagram: {
					min: 6,
					url: {
						require_protocol: true,
					},
				},
				github: {
					min: 6,
					url: {
						require_protocol: true,
					},
				},
				personalWebsite: {
					min: 6,
					url: {
						require_protocol: true,
					},
				},
				cv: {
					required: true,
				},
				transcript: {},
				industryCategory: {
					required: true,
				},
			},
			heading: 'Please select your Extracurricular Activities',
		};
	},
	updated() {
		if (this.modal || this.pdfError) {
			this.clearField();
		}
	},
	methods: {
		confirmSelection(value) {
			this.selectedActivities = value;
			this.extraCirricularActivities = value.map(x => x.value);
			this.openActivityModal = false;
			this.addExtraCirricularActivities();
		},
		addActivity() {
			this.openActivityModal = true;
		},
		closeActivityModal() {
			this.openActivityModal = false;
			this.isActivitiesSelected = true;
		},
		previewPdf(event) {
			this.uploadValue = 0;
			const file = event.target.files[0];
			this.name = event.target.name;

			//Checks if file size is greater than 2MB
			if (file.size > 2 * 1024 * 1024) {
				this.fieldErrorCheck(this.name);
				return (this.modal = true);
			}
			//Checks if file is a PDF
			else if (file.type !== 'application/pdf') {
				this.fieldErrorCheck(this.name);
				return (this.pdfError = true);
			}

			//Uploads the file and generates Firebase link
			this.fieldErrorCheck();
			this.fileUpload(file, this.name.toString());
		},
		clearField() {
			if (this.name === 'CV') {
				this.cvError = true;
			} else if (this.name === 'ID') {
				this.idError = true;
			} else if (this.name === 'Portfolio') {
				this.portfolioError = true;
			} else if (this.name === 'certificate-1') {
				this.cert1Error = true;
			} else if (this.name === 'certificate-2') {
				this.cert2Error = true;
			} else if (this.name === 'certificate-3') {
				this.cert3Error = true;
			}
		},
		fieldErrorCheck() {
			if (this.name === 'CV') {
				this.cvError = false;
			} else if (this.name === 'ID') {
				this.idError = false;
			} else if (this.name === 'Portfolio') {
				this.portfolioError = false;
			} else if (this.name === 'certificate-1') {
				this.cert1Error = false;
			} else if (this.name === 'certificate-2') {
				this.cert2Error = false;
			} else if (this.name === 'certificate-3') {
				this.cert3Error = false;
			}
		},
		fileUpload(data, location) {
			this.loading = true;
			const storageRef = firebase
				.storage()
				.ref()
				.child('users/students/' + this.alias + '/' + location + '/' + data.name)
				.put(data);
			storageRef.on(
				`state_changed`,
				snapshot => {
					this.uploadCV = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				},
				error => {
					console.log(error.message);
				},
				async () => {
					this.uploadCV = 100;
					this.firebaseUrl = await storageRef.snapshot.ref.getDownloadURL();
					if (location === 'ID') {
						this.id = data.name;
						this.student.update({
							id: { fileName: this.id, url: this.firebaseUrl },
							lastModified: moment(Date.now()).format('L'),
						});
						this.loading = false;
					}
					if (location === 'CV') {
						this.cv = data.name;
						this.student.update({
							cv: { fileName: this.cv, url: this.firebaseUrl },
							lastModified: moment(Date.now()).format('L'),
						});
						this.loading = false;
					}
					if (location === 'Portfolio') {
						this.portfolio = data.name;
						this.student.update({
							portfolio: { fileName: this.portfolio, url: this.firebaseUrl },
							lastModified: moment(Date.now()).format('L'),
						});
						this.loading = false;
					}
					if (location === 'certificate-1') {
						this.certificate1 = data.name;
						this.student.update({
							certificate1: { fileName: this.certificate1, url: this.firebaseUrl },
							lastModified: moment(Date.now()).format('L'),
						});
						this.loading = false;
					}
					if (location === 'certificate-2') {
						this.certificate2 = data.name;
						this.student.update({
							certificate2: { fileName: this.certificate2, url: this.firebaseUrl },
							lastModified: moment(Date.now()).format('L'),
						});
						this.loading = false;
					}
					if (location === 'certificate-3') {
						this.certificate3 = data.name;
						this.student.update({
							certificate3: { fileName: this.certificate3, url: this.firebaseUrl },
							lastModified: moment(Date.now()).format('L'),
						});
						this.loading = false;
					}
				}
			);
		},
		modalHide() {
			this.modal = false;
		},
		PdfModalHide() {
			this.pdfError = false;
		},
		validate() {
			return this.$validator.validateAll().then(res => {
				this.$emit('on-validated', res);
				return res;
			});
		},
		addLinkedIn: function () {
			this.$emit('linkedIn', this.linkedIn);
		},
		addFacebook: function () {
			this.$emit('facebook', this.facebook);
		},
		addTwitter: function () {
			this.$emit('twitter', this.twitter);
		},
		addInstagram: function () {
			this.$emit('instagram', this.instagram);
		},
		addGithub: function () {
			this.$emit('github', this.github);
		},
		addCertificate1: function () {
			this.$emit('certificate1', this.certificate1);
		},
		addCertificate2: function () {
			this.$emit('certificate2', this.certificate2);
		},
		addCertificate3: function () {
			this.$emit('certificate3', this.certificate3);
		},
		addPersonalWebsite: function () {
			this.$emit('personalWebsite', this.personalWebsite);
		},
		addPortfolio: function () {
			this.$emit('portfolio', this.portfolio);
		},
		addId: function () {
			this.$emit('id', this.id);
		},
		addCV: function () {
			this.$emit('cv', this.cv);
		},
		addIndustryCategory: function () {
			this.$emit('industryCategory', this.industryCategory);
		},
		addExtraCirricularActivities: function () {
			this.$emit('extraCirricularActivities', this.extraCirricularActivities);
		},
		...mapActions(['addStudentDetails']),
	},
	watch: {
		linkedIn() {
			this.touched.linkedIn = true;
		},
		facebook() {
			this.touched.facebook = true;
		},
		twitter() {
			this.touched.twitter = true;
		},
		instagram() {
			this.touched.instagram = true;
		},
		github() {
			this.touched.github = true;
		},
		personalWebsite() {
			this.touched.personalWebsite = true;
		},
		portfolio() {
			this.touched.portfolio = true;
		},
		certificate1() {
			this.touched.certificate1 = true;
		},
		certificate2() {
			this.touched.certificate2 = true;
		},
		certificate3() {
			this.touched.certificate3 = true;
		},
		id() {
			this.touched.id = true;
		},
		cv() {
			this.touched.cv = true;
		},
		industryCategory() {
			this.touched.industryCategory = true;
		},
	},
	created() {
		this.user = firebase.auth().currentUser;
		db.collection('Settings')
			.doc('Job Category Drop-down Lists')
			.get()
			.then(doc => {
				this.list = doc.data();
			});

		let ref = db.collection('users');
		ref.where('userId', '==', this.user.uid)
			.get()
			.then(snapshot => {
				snapshot.forEach(doc => {
					this.alias = doc.id;
					this.student = db.collection('students').doc(doc.id);
					this.student
						.get()
						.then(doc => {
							if (doc.exists) {
								let data = doc.data();
								this.linkedIn = data.linkedIn;
								this.facebook = data.facebook;
								this.twitter = data.twitter;
								this.instagram = data.instagram;
								this.github = data.github;
								this.personalWebsite = data.personalWebsite;
								this.portfolio = data.portfolio.fileName || null;
								this.id = data.id.fileName || null;
								this.cv = data.cv.fileName || null;
								this.certificate1 = data.certificate1.fileName || null;
								this.certificate2 = data.certificate2.fileName || null;
								this.certificate3 = data.certificate3.fileName || null;
								this.industryCategory = data.interestedIndustries;
							}
						})
						.catch(err => {
							console.log(err.message);
						});
				});
			});
	},
};
</script>
<style scoped>
.activitySelectionModal {
	display: flex;
	flex-direction: column;
}
.error {
	color: red;
}
@media only screen and (max-width: 768px) {
	.md-field label {
		font-size: 11px;
	}
}
</style>
